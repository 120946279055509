import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpModule } from '@angular/http';

import { PrincipalComponent } from '../app/modulos/core/principal/principal.component';
import { CoreModule } from '../app/modulos/core/core.module';
import { environment } from "../environments/environment";
import { NgxWebstorageModule } from 'ngx-webstorage';
import { GlobalService } from "./servicios/global.service";
import { AuthGuardService } from './servicios/auth-guard.service';

import { DeviceDetectorModule } from "ngx-device-detector";


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpModule,
    NgxWebstorageModule.forRoot(),
    DeviceDetectorModule.forRoot()
  ],
  providers: [GlobalService, AuthGuardService],
  bootstrap: [PrincipalComponent]
})
export class AppModule { }
