import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { GlobalService } from '../../../servicios/global.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  visible: boolean = false;
  suscripcionLoader: Subscription;

  constructor(
    private servicioGlobal: GlobalService
  ) { }

  ngOnInit() {
    this.suscripcionLoader = this.servicioGlobal.loaderActivo.subscribe(
      valor => {
        this.visible = valor;
      }
    );
  }

}
