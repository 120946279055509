import { Privilegio } from './privilegio';
import { TipoServicio, tipoBoleto } from "./tipos-formulario-aplicacion";

export class Perfil {

    identificador: string;
    nombre: string;
    privilegios: Privilegio[] | TipoServicio[] | tipoBoleto[];
    fechaCreado: Date | string | number;

    constructor() { 

        this.identificador = '';
        this.nombre = '';
        this.privilegios = [];
        this.fechaCreado = new Date();

    }
}