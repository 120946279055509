import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MzNavbarModule, MzSidenavModule, MzIconMdiModule, MzSpinnerModule, MzToastModule, MzModalModule, MzButtonModule } from 'ngx-materialize';

import { PrincipalComponent } from './principal/principal.component';
import { BarraNavegacionComponent } from './barra-navegacion/barra-navegacion.component';
import { routing } from './app.routing';
import { LoaderComponent } from './loader/loader.component'
import { UsuariosService } from "../../servicios/usuarios.service";
import { PerfilesService } from "../../servicios/perfiles.service";


@NgModule({
  declarations: [PrincipalComponent, BarraNavegacionComponent, LoaderComponent],
  imports: [
    CommonModule,
    routing,
    MzNavbarModule,
    MzSidenavModule,
    MzIconMdiModule,
    MzSpinnerModule,
    MzModalModule,
    MzToastModule,
    MzButtonModule
  ],
  providers: [
		UsuariosService,
		PerfilesService
	],
  exports: [
		PrincipalComponent
	]
})
export class CoreModule { }
