export class Privilegio {

    identificador: string;
    identificadorPerfil: string;
    modulo: string;
    lista: boolean;
    nuevo: boolean;
    editar: boolean;
    eliminar: boolean;

    cancelacion: boolean;
    reportes: boolean;
    descargaIndividual: boolean;
    descargaMasiva: boolean;
    reenvioCorreo: boolean;
    detalle: boolean;

    refacturacion: boolean;
    modificarRfc: boolean;

    constructor() {

        this.identificador = '';
        this.identificadorPerfil = '';
        this.modulo = '';
        this.lista = false;
        this.nuevo = false;
        this.editar = false;
        this.eliminar = false;

        this.cancelacion = false;
        this.reportes = false;
        this.descargaIndividual = false;
        this.descargaMasiva = false;
        this.reenvioCorreo = false;
        this.detalle = false;

        this.refacturacion = false;
        this.modificarRfc = false;
        
    }
}