import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GlobalService {

    private loaderActivoFuente: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public loaderActivo = this.loaderActivoFuente.asObservable();

    private sesionActivaFuente: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public sesionActiva = this.sesionActivaFuente.asObservable();

    private informacionActivaFuente: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public informacionActiva = this.informacionActivaFuente.asObservable();

    private contadorLoaderFuente: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public cantadorLoader: number = 0;

    cambiarEstadoLoader(valor: boolean): void {
        this.cantadorLoader = this.contadorLoaderFuente.getValue();

        if (valor) {
            this.cantadorLoader++;
        } else {
            if (this.cantadorLoader > 0) {
                this.cantadorLoader--;
            }
        }

        this.contadorLoaderFuente.next(this.cantadorLoader);

        if (!valor) {
            if (this.cantadorLoader == 0) {
                this.loaderActivoFuente.next(valor);
            }
        } else {
            this.loaderActivoFuente.next(valor);
        }
    }

    cambiarEstadoSesion(valor: boolean): void {
        this.informacionActivaFuente.next(valor);
    }
    
    cambiarEstadoInformacion(valor: boolean): void {
        this.sesionActivaFuente.next(valor);
    }

}