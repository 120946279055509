import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

import { Observable, Subscription, observable, throwError } from 'rxjs';
import { map, catchError, timeout } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

import { Usuario } from "../entidades/usuario";
import { Perfil } from "../entidades/perfil";
import { GlobalService } from "./global.service";
import { PerfilesService } from "./perfiles.service";
import { environment } from 'src/environments/environment';

import * as moment from 'moment';
import * as cryptoJS from 'crypto-js';
import { CorreoRegistro } from '../entidades/correo-registro';
import { Body } from '@angular/http/src/body';
import { Empresa } from '../entidades/empresa';


@Injectable()
export class UsuariosService {
    tiempo: number = 24000000;

    constructor(
        private http: Http,
        private fireDB: AngularFirestore,
        private fireAuth: AngularFireAuth,
        private almacen: LocalStorageService,
        private servicioPerfil: PerfilesService
    ) { }

    cargarUsuarios(): Observable<Usuario[]> {
        let usuarioColeccion: AngularFirestoreCollection<Usuario> = this.fireDB.collection<Usuario>('usuarios');
        let suscriptor: Subscription;
        let usuarios: Usuario[] = [];

        return new Observable<Usuario[]>(observador => {
            suscriptor = usuarioColeccion.snapshotChanges().subscribe(
                datos => {

                    datos.forEach(dato => {

                        let datoActual = dato.payload.doc.data();
                        let identificador = dato.payload.doc.id;
                        let usuario: Usuario = new Usuario();

                        usuario = datoActual;
                        usuario.identificador = identificador;

                        usuarios.push(usuario);
                    });

                    //console.log(usuarios);
                    observador.next(usuarios);
                    observador.complete();

                },
                error => {
                    console.log(error);
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.complete();
                }
            );
        });
    }

    cargarUsuario(identificador: string): Observable<Usuario> {
        return new Observable<Usuario>(observador => {

            console.log("[Identificador]", identificador);

            let usuarioDoc = this.fireDB.doc<Usuario>('usuarios/' + identificador);

            usuarioDoc.valueChanges().subscribe(
                usuario => {
                    let usuarioNuevo: Usuario = new Usuario();

                    usuarioNuevo = usuario;
                    usuarioNuevo.identificador = identificador;
                    console.log("[Usuario_Recibido]", usuarioNuevo);

                    observador.next(usuarioNuevo);
                    observador.complete();
                    return usuarioNuevo;

                },
                error => {
                    console.log(error);
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.complete();
                }
            );
        });
    }

    cargarUsuarioLocal(): Observable<Usuario> {
        return new Observable(observador => {
            try {

                let cadena = this.almacen.retrieve('usuario');
                let datos = cadena;
                let hashd = JSON.parse(cryptoJS.AES.decrypt(datos, environment.cryptoKey).toString(cryptoJS.enc.Utf8));
                observador.next(hashd);
                observador.complete();

            } catch (error) {
                observador.next(new Usuario());
                observador.complete();
                console.log(error);
            }
        });
    }

    eliminarUsuarioLocal() {
        this.almacen.clear('usuario');
        this.almacen.clear('perfil');
    }

    eliminarUsuario(identificador: string) {
        this.fireDB.collection("usuarios").doc(identificador).delete();
    }

    inicioSesion(correo: string, clave: string): Observable<any> {
        return new Observable<any>(observador => {
            console.log("correo a autenticar:", correo);
            this.fireAuth.auth.setPersistence('session')
                .then(respuestaPersistencia => {
                    this.fireAuth.auth.signInWithEmailAndPassword(correo, clave)
                        .then(respuesta => {
                            let operacionUsuarios: Observable<Usuario>;
                            operacionUsuarios = this.cargarUsuarioSesion(respuesta.user.uid);
                            operacionUsuarios.subscribe(
                                respuestaUsuario => {
                                    let usuarioCargado: Usuario = respuestaUsuario;
                                    let operacionLocal: Observable<boolean>;
                                    operacionLocal = this.guardarUsuarioLocal(usuarioCargado);
                                    operacionLocal.subscribe(
                                        respuestaLocal => {
                                            let operacionPerfil: Observable<Perfil> = this.servicioPerfil.cargarPerfil(usuarioCargado.identificadorPerfil);
                                            operacionPerfil.subscribe(
                                                respuestaPerfil => {
                                                    let operacionLocalPerfil: Observable<boolean> = this.servicioPerfil.guardarPerfilLocal(respuestaPerfil);
                                                    operacionLocalPerfil.subscribe(
                                                        respuestaLocalPerfil => {
                                                            //console.log("1________1")
                                                            observador.next(respuestaPerfil);
                                                            observador.complete();
                                                        },
                                                        error => {
                                                            console.log("nivel 1", error)
                                                            observador.error('Ocurrió un error al comunicarse con el servidor');
                                                            observador.complete();
                                                        }
                                                    )
                                                },
                                                error => {
                                                    console.log("nivel 2", error)
                                                    observador.error('Ocurrió un error al comunicarse con el servidor');
                                                    observador.complete();
                                                }
                                            )
                                        },
                                        error => {
                                            console.log("nivel 3", error)
                                            observador.error('Ocurrió un error al comunicarse con el servidor');
                                            observador.complete();
                                        }
                                    );
                                },
                                error => {
                                    console.log("nivel 4", error)
                                    observador.error('Ocurrió un error al comunicarse con el servidor');
                                    observador.complete();
                                }
                            );
                        })
                        .catch(error => {
                            let errorRegistro: string = '';

                            switch (error.code) {
                                case 'auth/invalid-email':
                                    errorRegistro = 'El correo electrónico proporcionado no es valido';
                                    break;
                                case 'auth/user-disabled':
                                    errorRegistro = 'El usuario ingresado no es válido';
                                    break;
                                case 'auth/user-not-found':
                                    errorRegistro = 'Correo o clave incorrectos, por favor revise sus credenciales';
                                    break;
                                case 'auth/wrong-password':
                                    errorRegistro = 'Correo o clave incorrectos, por favor revise sus credenciales';
                                    break;
                            }
                            console.log("nivel 5", errorRegistro)
                            observador.error(errorRegistro);
                            observador.complete();
                        });
                })
                .catch(error => {
                    console.log("nivel 6", error)
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.complete();
                });
        });
    }

    cargarUsuarioSesion(idetificador: string): Observable<Usuario> {
        let usuarioColeccion: AngularFirestoreCollection<Usuario> = this.fireDB.collection<Usuario>('usuarios', ref => ref.where('identificadorSesion', '==', idetificador));
        // console.log(idetificador);
        return new Observable<Usuario>(observador => {
            usuarioColeccion.snapshotChanges().subscribe(
                datos => {
                    let usuario: Usuario = new Usuario();
                    // console.log(datos);
                    datos.forEach(dato => {
                        // console.log(dato);
                        // console.log(dato.payload.doc.data());
                        let datoActual = dato.payload.doc.data();
                        let identificador = dato.payload.doc.id;

                        usuario = new Usuario();
                        usuario = datoActual;
                        usuario.identificador = identificador;
                        usuario.identificadorSesion = datoActual.identificadorSesion;
                        usuario.identificadorPerfil = datoActual.identificadorPerfil;
                        usuario.fechaCreado = new Date(parseInt(datoActual.fechaCreado.toString()));

                        // console.log(JSON.stringify(usuario, null, 4));
                    });


                    observador.next(usuario);
                    observador.complete();
                },
                error => {
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.complete();
                }
            );
        });
    }

    guardarUsuario(usuario: Usuario): Observable<any> {
        return new Observable<any>(observador => {
            usuario.fechaCreado = moment(usuario.fechaCreado).toDate().getTime();

            let usuarioDoc = this.fireDB.doc<Usuario>('usuarios/' + usuario.identificador);
            usuarioDoc.update(JSON.parse(JSON.stringify(usuario)))
                .then(() => {
                    observador.next(true);
                    observador.complete();
                })
                .catch(() => {
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.complete();
                });
        });
    }

    nuevoUsuario(usuario: Usuario): Observable<string> {
        let usuarioColeccion: AngularFirestoreCollection<Usuario> = this.fireDB.collection<Usuario>('usuarios');
        return new Observable<string>(observador => {

            usuario.fechaCreado = new Date().getTime();

            usuarioColeccion.add(JSON.parse(JSON.stringify(usuario)))
                .then(respuestaPerfil => {
                    console.log("Respuesta Perfil: ", respuestaPerfil)
                    usuario.identificador = respuestaPerfil.id;
                    let observa: Subscription;
                    observa = this.guardarUsuario(usuario).subscribe(
                        suscribe => {

                        }
                    )
                    observador.next(respuestaPerfil.id);
                    observador.complete();
                })
                .catch((error) => {
                    console.log(error);
                    observador.error('Error al generar perfil');
                    observador.complete();
                });
        });
    }

    guardarUsuarioLocal(usuario: Usuario): Observable<boolean> {
        return Observable.create(observador => {
            let respuesta = true;
            try {
                let datos = usuario;
                let hash = cryptoJS.AES.encrypt(JSON.stringify(datos), environment.cryptoKey);
                this.almacen.store('usuario', "" + hash.toString());
                observador.next(true);
                observador.complete();
            } catch (error) {
                respuesta = false;
                observador.next(respuesta);
                observador.complete();
            }
        });
    }

    registroUsuario(usuario: Usuario): Observable<any> {
        let usuarioColeccion: AngularFirestoreCollection<Usuario> = this.fireDB.collection<Usuario>('usuarios');

        return new Observable<any>((observador) => {
            this.fireAuth.auth.createUserWithEmailAndPassword(usuario.correo, usuario.clave)
                .then(respuesta => {
                    usuario.identificadorSesion = respuesta.user.uid;
                    usuario.clave = '';
                    usuario.fechaCreado = moment(usuario.fechaCreado).toDate().getTime();

                    let usuarioDoc = this.fireDB.doc<Usuario>('usuarios/' + usuario.identificador);
                    usuarioDoc.update(JSON.parse(JSON.stringify(usuario)))
                        .then(() => {
                            let usuarioCargado: Usuario = usuario;
                            let operacionLocal: Observable<boolean>;
                            operacionLocal = this.guardarUsuarioLocal(usuarioCargado);
                            operacionLocal.subscribe(
                                respuestaLocal => {
                                    let operacionPerfil: Observable<Perfil> = this.servicioPerfil.cargarPerfil(usuarioCargado.identificadorPerfil);
                                    operacionPerfil.subscribe(
                                        respuestaPerfil => {
                                            let operacionLocalPerfil: Observable<boolean> = this.servicioPerfil.guardarPerfilLocal(respuestaPerfil);
                                            operacionLocalPerfil.subscribe(
                                                respuestaLocalPerfil => {
                                                    console.log("1________1")
                                                    observador.next(respuestaPerfil);
                                                    observador.complete();
                                                },
                                                error => {
                                                    console.log("nivel 1", error)
                                                    observador.error('Ocurrió un error al comunicarse con el servidor');
                                                    observador.complete();
                                                }
                                            )
                                        },
                                        error => {
                                            console.log("nivel 2", error)
                                            observador.error('Ocurrió un error al comunicarse con el servidor');
                                            observador.complete();
                                        }
                                    )
                                },
                                error => {
                                    console.log("nivel 3", error)
                                    observador.error('Ocurrió un error al comunicarse con el servidor');
                                    observador.complete();
                                });
                        },
                            error => {
                                console.log(error);
                                observador.complete();
                            })
                        .catch(() => {
                            observador.error('Ocurrió un error al comunicarse con el servidor');
                            observador.complete();
                        });


                })
                .catch(error => {
                    let errorRegistro: string = '';

                    switch (error.code) {
                        case 'auth/email-already-in-use':
                            errorRegistro = 'El correo electrónico YA ESTA EN USO por otro usuario, intente con un correo distinto';
                            break;
                        case 'auth/invalid-email':
                            errorRegistro = 'El correo electrónico NO es valido';
                            break;
                        case 'auth/operation-not-allowed':
                            errorRegistro = 'Esta operación no esta permitida';
                            break;
                        case 'auth/weak-password':
                            errorRegistro = 'La clave es MUY SIMPLE, por favor intente una clave distinta';
                            break;
                        default:
                            errorRegistro = 'Ocurrió un error al guardar el usuario, por favor intente nuevamente';
                            break
                    }

                    observador.error(errorRegistro);
                    observador.complete();

                });
        });
    }

    verificarEnlaceReinicio(codigo: string): Observable<any> {
        let usuarioColeccion: AngularFirestoreCollection<Usuario> = this.fireDB.collection<Usuario>('usuarios');

        return new Observable<any>((observador) => {
            this.fireAuth.auth.verifyPasswordResetCode(codigo)
                .then(respuesta => {
                    observador.next(respuesta);
                    console.log(respuesta);
                    observador.complete();
                })
                .catch(error => {
                    console.log(error);
                    observador.error('Error al verificar enlace');
                    observador.complete();

                });
        });
    }

    reiniciarPassword(codigo: string, nuevoPassword: string): Observable<any> {
        let usuarioColeccion: AngularFirestoreCollection<Usuario> = this.fireDB.collection<Usuario>('usuarios');

        return new Observable<any>((observador) => {
            this.fireAuth.auth.confirmPasswordReset(codigo, nuevoPassword)
                .then(respuesta => {
                    console.log(respuesta);
                    observador.next(respuesta);
                    observador.complete();
                })
                .catch(error => {
                    console.log(error)
                    observador.error('Error al reiniciar password');
                    observador.complete();

                });
        });
    }

    enviarCorreo(email: string): Observable<any> {
        console.log(email);
        return new Observable<any>((observador) => {
            this.fireAuth.auth.sendPasswordResetEmail(email)
                .then(() => {
                    observador.next(true);
                    observador.complete();
                })
                .catch(error => {
                    console.log(error);
                    observador.error('Error al enviar correo');
                    observador.complete();

                });
        });
    }

    envioCorreoUsuario(registro: CorreoRegistro, token: string): Observable<string> {

        registro.url = environment.urlCorreo + token;

        let enlace = environment.envioCorreo;

        let objeto: any = registro;
        let bodyString = objeto;

        let headers = new Headers({});
        headers = new Headers({ "Content-Type": "application/json", 'Authorization': environment.clave["PaqueteriaDev"] });
        let options = new RequestOptions({ headers: headers });

        console.log("[enlace]", enlace);
        console.log(options);

        return this.http.post(enlace, bodyString, options).pipe(
            timeout(this.tiempo)
            , map((res: Response) => {
                let datos: any = res.json().respuesta;
                console.log(datos);
                console.log("recibido:", JSON.stringify(res.json().respuesta, null, 4));

                return datos;
            })
            , catchError((error: any) => throwError(error || 'Error en el servidor'))
        );

    }

    cargarUsuarioErp(numeroEmpleado: string): Observable<string> {

        let enlace = environment.urlErpUsuario;

        let headers = new Headers({});
        headers = new Headers({ "Content-Type": "application/json", "Authorization": environment.clave["PaqueteriaDev"] });
        let options = new RequestOptions({ headers: headers });

        console.log("[enlace]", enlace);
        console.log(options);

        return this.http.post(enlace, numeroEmpleado, options).pipe(
            timeout(this.tiempo)
            , map((res: Response) => {
                let datos: any = res.json().respuesta;
                console.log(datos);
                //console.log("recibido:", JSON.stringify(res.json().respuesta, null, 4));

                return datos;
            })
            , catchError((error: any) => throwError(error || 'Error en el servidor'))
        );

    }

    obtenerEmpresas(): Observable<Empresa[]> {

        let enlace = environment.urlEmpresas;

        let headers = new Headers({});
        headers = new Headers({ "Content-Type": "application/json", 'Authorization': environment.clave["FacturacionAdministracionDev"] });
        let options = new RequestOptions({ headers: headers });

        console.log("[enlace]", enlace);
        console.log(options);

        return this.http.post(enlace, "", options).pipe(
            timeout(this.tiempo)
            , map((res: Response) => {
                let datos: any = res.json().respuesta;
                console.log(datos);
                console.log("recibido:", JSON.stringify(res.json().respuesta, null, 4));

                return datos;
            })
            , catchError((error: any) => throwError(error || 'Error en el servidor'))
        );

    }



}