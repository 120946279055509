import { Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import { Observable, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';

import { Perfil } from '../entidades/perfil';
import { PerfilTemplate } from '../entidades/perfil-template';
import { environment } from 'src/environments/environment';

import * as cryptoJS from 'crypto-js';

@Injectable()
export class PerfilesService {

    constructor(
        private http: Http,
        private fireDB: AngularFirestore,
        private almacen: LocalStorageService,
    ) { }

    cargarPerfiles(): Observable<Perfil[]> {

        let perfilColeccion: AngularFirestoreCollection<Perfil> = this.fireDB.collection<Perfil>('perfiles');
        let perfiles: Perfil[];
        let suscriptor: Subscription;

        return new Observable<Perfil[]>(observador => {
            suscriptor = perfilColeccion.snapshotChanges().subscribe(
                datos => {
                    perfiles = [];
                    datos.forEach(dato => {
                        let datoActual = dato.payload.doc.data();
                        let identificador = dato.payload.doc.id;

                        let perfil: Perfil = new Perfil();
                        perfil.identificador = identificador;
                        perfil.nombre = datoActual.nombre;
                        perfil.fechaCreado = new Date().setDate(parseInt(datoActual.fechaCreado.toString()));
                        perfiles.push(perfil);
                    });

                    observador.next(perfiles);
                },
                error => {
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.complete();
                }
            );
        });
    }

    cargarPerfilLocal(): Observable<Perfil> {
        return Observable.create(observador => {
            try {
                let cadena = this.almacen.retrieve('perfil');
                let datos = cadena;
                let hashd = JSON.parse(cryptoJS.AES.decrypt(datos, environment.cryptoKey).toString(cryptoJS.enc.Utf8));
                console.log("}}}",hashd);
                observador.next(hashd);
                observador.complete();

            } catch (error) {
                observador.next(new Perfil());
                observador.complete();
                console.log(error);
            }
        });
    }

    eliminarPerfil(identificador: string) {

        this.fireDB.collection("perfiles").doc(identificador).delete();

    }

    nuevoPerfil(perfil: Perfil): Observable<string> {
        let usuarioColeccion: AngularFirestoreCollection<Perfil> = this.fireDB.collection<Perfil>('perfiles');
        return new Observable<string>(observador => {

            perfil.fechaCreado = new Date().getTime();

            usuarioColeccion.add(JSON.parse(JSON.stringify(perfil)))
                .then(respuestaPerfil => {
                    observador.next(respuestaPerfil.id);
                    observador.complete();
                })
                .catch((error) => {
                    console.log(error);
                    observador.error('Error al generar perfil');
                    observador.complete();
                });
        });
    }

    cargarModulos(): Observable<PerfilTemplate[]> {
        return this.http.get("../../assets/datos/modulos.json")
            .pipe(
                map((respuesta) => {
                    let datos = respuesta.json();
                    console.log(JSON.stringify(datos, null, 4));
                    return datos;
                }),
                catchError((error: any) => {
                    throw Observable.throw(error);
                })
            );
    }

    guardarPerfilLocal(perfil: Perfil): Observable<boolean> {
        return Observable.create(observador => {
            let respuesta = true;
            try {
                let datos = perfil;
                let hash = cryptoJS.AES.encrypt(JSON.stringify(datos), environment.cryptoKey);
                this.almacen.store('perfil', "" + hash.toString());
                observador.next(true);
                observador.complete();
            } catch (error) {
                respuesta = false;
                observador.next(respuesta);
                observador.complete();
            }
        });
    }

    cargarPerfil(identificador: string): Observable<Perfil> {
        return new Observable<Perfil>(observador => {
            let perfilDoc = this.fireDB.doc<Perfil>('perfiles/' + identificador);
            perfilDoc.valueChanges().subscribe(
                perfil => {

                    let perfilNuevo: Perfil = new Perfil();

                    perfilNuevo.identificador = identificador;
                    perfilNuevo.nombre = perfil.nombre;
                    perfilNuevo.fechaCreado = perfil.fechaCreado;
                    perfilNuevo.privilegios = perfil.privilegios;

                    console.log(identificador);
                    console.log(perfilNuevo);

                    observador.next(perfilNuevo);
                    observador.complete();
                    return perfilNuevo;

                },
                error => {
                    console.log(error);
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.complete();
                }
            );
        });
    }

    actualizarPerfil(perfil: Perfil): Observable<any> {
        console.log(perfil);
        return new Observable<any>(observador => {
            let perfilDoc: AngularFirestoreDocument<Perfil> = this.fireDB.doc<Perfil>('perfiles/' + perfil.identificador);
            perfilDoc.update(JSON.parse(JSON.stringify(perfil)))
                .then(respuestaPerfil => {
                    observador.next(true);
                    observador.complete();
                })
                .catch(error => {
                    console.log(error);
                    observador.error('Ocurrió un error al comunicarse con el servidor');
                    observador.next(false);
                    observador.complete();
                });
        });
    }

}