import { ModuleWithProviders, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/usuarios/inicio-sesion',
        pathMatch: 'full'
    },
    {
        path: 'inicio',
        loadChildren: '../inicio/inicio.module#InicioModule'
    },
    {
        path: 'contenido/:identificador',
        loadChildren: '../contenido/contenido.module#ContenidoModule'
    },
    {
        path: 'usuarios',
        loadChildren: '../usuarios/usuarios.module#UsuariosModule'
    },
    {
        path: 'perfiles',
        loadChildren: '../perfiles/perfiles.module#PerfilesModule' 
    },
    {
        path: 'aplicaciones',
        loadChildren: '../aplicaciones/aplicaciones.module#AplicacionesModule' 
    },
    {
        path: 'origenes-destinos/:identificadorOrigenesDestinos',
        loadChildren: '../origenes-destinos/origenes-destinos.module#OrigenesDestinosModule'
    },
    {
        path: 'oficinas',
        loadChildren: '../oficinas/oficinas.module#OficinasModule'
    },
    {
        path: 'configuracion/:modulo',
        loadChildren: '../configuracion/configuracion.module#ConfiguracionModule'
    },
    {
        path: 'operaciones/:modulo',
        loadChildren: '../operaciones/operaciones.module#OperacionesModule'
    },
    {
        path: 'usuarios-modulo/:modulo',
        loadChildren: '../usuarios-modulo/usuarios-modulo.module#UsuariosModuloModule'
    },
    {
        path: 'sucursales/:modulo',
        loadChildren: '../sucursales/sucursales.module#SucursalesModule'
    },
    {
        path: 'ciudades/:modulo',
        loadChildren: '../ciudades/ciudades.module#CiudadesModule'
    },
    {
        path: 'estados/:modulo',
        loadChildren: '../estados/estados.module#EstadosModule'
    },
    {
        path: 'autobuses/:modulo',
        loadChildren: '../autobuses/autobuses.module#AutobusesModule'
    },
    {
        path: 'facturas/:modulo',
        loadChildren: '../facturas/facturas.module#FacturasModule'
    },
    {
        path: 'nominas/:modulo',
        loadChildren: '../nominas/nominas.module#NominasModule'
    },
    {
        path: 'reportes/:modulo',
        loadChildren: '../reportes/reportes.module#ReportesModule'
    },
    {
        path: 'recuperacion/:modulo',
        loadChildren: '../recuperacion/recuperacion.module#RecuperacionModule'
    },
    {
        path: 'catalogos/:modulo',
        loadChildren: '../catalogos/catalogos.module#CatalogosModule'
    },
    {
        path: 'refacturas/:modulo',
        loadChildren: '../refacturas/refacturas.module#RefacturasModule'
    },

];

export const routing: ModuleWithProviders = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled', useHash: true });