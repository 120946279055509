import { Empresa } from "./empresa";

export class Usuario {

    identificador: string;
    identificadorSesion: string;
    identificadorPerfil: string;
    nombre: string;
    correo: string;
    clave: string;
    confirmacionClave: string;
    perfil: string;
    estado: string;
    fechaCreado: Date | number | string;
    esOperador: string;
    correoJefe: string;
    numeroEmpleado: string;
    enviarAutenticacionJefe: string;
    empresas: string[];

    constructor() {

        this.identificador = '';
        this.identificadorSesion = '';
        this.identificadorPerfil = '';
        this.nombre = '';
        this.correo = '';
        this.clave = '';
        this.confirmacionClave = '';
        this.perfil = '';
        this.estado = '';
        this.fechaCreado = '';
        this.esOperador = '';
        this.correoJefe = '';
        this.numeroEmpleado = '';
        this.enviarAutenticacionJefe = '';
        this.empresas = [];

    }
}

export class UsuarioJson {

    nombre: string;
    correo: string;
    perfil: string;

    constructor() {

        this.nombre = '';
        this.correo = '';
        this.perfil = '';

    }
}

export class ValidacionUsuario {

    usuario: Usuario;
    paso: boolean;
    motivo: string;

    constructor() {

        this.usuario = new Usuario();
        this.paso = false;
        this.motivo = '';

    }
}

